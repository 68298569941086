import React from "react";
import BlogLayout from "../components/BlogLayout";
import Blog from "../components/Blog";
import BlogHeader from "../components/BlogHeader";
import BlogSection from "../components/BlogSection";
import BlogImage from "../components/BlogImage";

function RectifiedFlowsPost(): JSX.Element {
  return (
    <BlogLayout>
      <Blog
        title="Going with the flow"
        author="Sanjeev"
        publishDate={new Date()}
      >
        <BlogHeader content="Introduction" size="md" />
        <BlogSection
          title="Getting Started"
          content={`What are rectified flows?

          Let's find out!
          
          `}
        />
        <BlogImage
          src="/path/to/image.jpg"
          alt="Descriptive alt text"
          caption="A beautiful sunset over the mountains"
        />
        <BlogSection
          title="Getting Started"
          content="This is the main content of the section that will appear in the larger left column..."
          sidenotes="This interesting side note will appear in orange on the right side"
        />
      </Blog>
    </BlogLayout>
  );
}

export default RectifiedFlowsPost;

// components/BlogMenu.tsx
import React from "react";
import { Box, VStack, Heading, Link } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

interface BlogLink {
  title: string;
  path: string;
}

const blogLinks: BlogLink[] = [
  { title: "Rectified Flows", path: "/rectified-flows" },
  { title: "Other Post", path: "/other-post" },
];

function BlogMenu(): JSX.Element {
  return (
    <Box width="250px" p="6" borderRight="1px" borderColor="gray.200">
      <Heading size="md" mb="6">
        Sanjeev's Blog
      </Heading>
      <VStack align="stretch" spacing="4">
        {blogLinks.map((link) => (
          <Link key={link.path} as={RouterLink} to={link.path}>
            {link.title}
          </Link>
        ))}
      </VStack>
    </Box>
  );
}

export default BlogMenu;

// Blog.tsx
import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { BlogProps } from "../types";

const Blog: React.FC<BlogProps> = ({
  title,
  author,
  publishDate,
  children,
}) => {
  return (
    <Box minW="800px" maxW="800px" mx="auto" p={4}>
      <Text fontSize="2xl" fontWeight="bold" mb={2}>
        {title}
      </Text>
      <Text fontSize="md" color="gray.600" mb={4}>
        By {author} • {publishDate.toLocaleDateString()}
      </Text>
      {children}
    </Box>
  );
};

export default Blog;

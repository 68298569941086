// BlogImage.tsx
import React from "react";
import { Box, Image, Text } from "@chakra-ui/react";
import { BlogImageProps } from "../types";

const BlogImage: React.FC<BlogImageProps> = ({ src, alt, caption }) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" mb={6}>
      <Image src={src} alt={alt} maxW="100%" height="auto" />
      {caption && (
        <Text fontSize="sm" color="gray.600" mt={2} fontStyle="italic">
          {caption}
        </Text>
      )}
    </Box>
  );
};

export default BlogImage;

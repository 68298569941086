import React from "react";
import { Box, Text, Heading, Flex } from "@chakra-ui/react";
import { BlogSectionProps } from "../types";

const BlogSection: React.FC<BlogSectionProps> = ({
  content,
  title,
  sidenotes,
}) => {
  return (
    <Flex mb={6} gap={4}>
      <Box flex="3" width="75%">
        {title && (
          <Heading as="h3" size="sm" mb={2}>
            {title}
          </Heading>
        )}
        <Text>{content}</Text>
      </Box>

      {sidenotes ? (
        <Box flex="1" width="25%">
          <Text fontSize="sm" color="orange.500">
            {sidenotes}
          </Text>
        </Box>
      ) : (
        <Box flex="1" width="25%"></Box>
      )}
    </Flex>
  );
};

export default BlogSection;

import React from "react";
import Markdown from "react-markdown";
import { Flex, Heading } from "@chakra-ui/react";

const markdown = `**What are they and who cares?**

Putting text on a childrens book image in the proper space.

## Choosing a font

## Kerning, line height, and text boxes

## Placement

#### Baseline

#### Semantic segmentation

#### Bounding boxes

`;

function Blog() {
  return (
    <>
      <Flex
        minWidth="600px"
        width="100vw"
        height="100vh"
        minHeight="800px"
        justifyContent="center"
      >
        <Flex width="500px" flexDirection="column">
          <Heading mb="4">Rectified flows</Heading>
          <Markdown>{markdown}</Markdown>
        </Flex>
      </Flex>
    </>
  );
}

export default Blog;

import React, { ReactNode } from "react";
import { Flex } from "@chakra-ui/react";
import BlogMenu from "./BlogMenu";

interface BlogLayoutProps {
  children: ReactNode;
}

function BlogLayout({ children }: BlogLayoutProps): JSX.Element {
  return (
    <Flex minWidth="900px" width="100vw" height="100vh" minHeight="800px">
      <BlogMenu />
      {children}
    </Flex>
  );
}

export default BlogLayout;

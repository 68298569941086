// BlogHeader.tsx
import React from "react";
import { Heading } from "@chakra-ui/react";
import { BlogHeaderProps } from "../types";

const BlogHeader: React.FC<BlogHeaderProps> = ({ content, size = "lg" }) => {
  return (
    <Heading as="h2" size={size} mb={4}>
      {content}
    </Heading>
  );
};

export default BlogHeader;
